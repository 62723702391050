<template>
  <div>
    <p class="mb-3">
      The maximum number of electrons that can be accommodated in orbitals having principal quantum
      number,
      <stemble-latex content="$\text{n}$" />, is:
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question200c',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options1: [
        {expression: '$2\\text{n}$', value: '2n'},
        {expression: '$\\text{n}^2$', value: 'n2'},
        {expression: '$2\\text{n}^2$', value: '2n2'},
        {expression: '$\\text{n}^2/2$', value: 'n2over2'},
        {expression: '$\\text{n}/2$', value: 'nover2'},
      ],
    };
  },
};
</script>
